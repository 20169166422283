<template>
  <div class="popup-add-excursion popup-flashmob">
    <div class="popup-add-excursion__wrapper">
      <Column :class="$viewport.desktop ? 'grid-6' : ''">
        <p class="popup-add-excursion__title">Экскурсионный флешмоб</p>
        <p class="popup-add-excursion__description">Благодарим за Ваш интерес к проекту. Давайте
          открывать Томскую область вместе!</p>

        <label class="popup-add-fact__label"
               for="fbFio">ФИО:</label>
        <input class="popup-add-fact__input"
               id="fbFio"
               type="text"
               v-model="form.fio">

        <label class="popup-add-fact__label"
               for="fbPhone">Телефон:</label>
        <input class="popup-add-fact__input"
               id="fbPhone"
               type="text"
               v-model="form.phone">

        <label class="popup-add-fact__label"
               for="fbEmail">Почта:</label>
        <input class="popup-add-fact__input"
               id="fbEmail"
               type="text"
               v-model="form.email">
        <div>
          <Button color="green"
                  :disabled="disabled"
                  v-on:click.native="signUp">Записаться
          </Button>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupFlashmob',
  data() {
    return {
      form: {},
    };
  },
  computed: {
    disabled() {
      if (Object.keys(this.form).length === 3) {
        // eslint-disable-next-line no-restricted-syntax
        for (const input in this.form) {
          if (this.form[input].length < 8) {
            return true;
          }
        }

        return false;
      }

      return true;
    },
  },
  methods: {
    signUp() {
      this.$store.dispatch('ADD_FLASHMOB_SIGN_UP', {
        event_id: this.$popup.event_id,
        ...this.form,
      });
      this.$store.commit('CLOSE_POPUP');
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup-flashmob {

  }
</style>
